import { useState } from 'react';
import { HStack, Stack } from '@chakra-ui/react';
import { useSharedState } from '../../../../@xmcloud/hooks';
import { IS_SM_SCREEN } from '../../../../@xmcloud/hooks/context/useSharedState';
import {
	Footer,
	Icon,
	LoadingSkeleton,
	RenderPlaceholder,
	Table,
	XMCSButton,
} from '../../../shared-components';
import { mdiPlus } from '@mdi/js';
import { EnvironmentTypesEnum } from '../../create-project/helpers';
import { useGetEnvironmentsByQuery } from '../../../services/environments';
import { pageSize } from '../../../../@xmcloud/utils/constants';
import { useEnvironmentsTableColumns } from '../columns/environments';
import { ColumnDef } from '@tanstack/react-table';
import { IGetEnvironmentResponseV2 } from '../../../models/environmentModel';
import { Main as CreateEnvironment } from '../../create-edit-environment/Main';
import { useGetEnvType } from '../hooks/useGetEnvType';

export const EnvironmentsPage: React.FC = () => {
	const [openCreateModal, setOpenCreateModal] = useState(false);
	const { state: isSmScreen } = useSharedState(IS_SM_SCREEN, false);

	const { isCmEnv, isEhEnv, envType, projectId } = useGetEnvType();

	const buttonText = isEhEnv ? 'Add editing host' : 'Add CM environment';

	const { data: environments, isLoading } = useGetEnvironmentsByQuery({
		type: envType as EnvironmentTypesEnum,
		projectId: projectId!,
	});

	const columns: ColumnDef<IGetEnvironmentResponseV2, any>[] =
		useEnvironmentsTableColumns(envType);

	const envLength = environments.length;

	if (isLoading) return <LoadingSkeleton amount={pageSize + 1} />;

	return (
		<Stack>
			<HStack justify="flex-end" py={isSmScreen ? 3 : 5}>
				{!isSmScreen && (
					<XMCSButton
						{...{
							text: buttonText,
							onClick: () => setOpenCreateModal(true),
							leftIcon: <Icon path={mdiPlus} />,
						}}
					/>
				)}
			</HStack>
			{!envLength ? (
				<RenderPlaceholder item="environments" />
			) : (
				<Table
					{...{
						columns: columns,
						tableData: environments,
						dataTestId: 'environments-table',
						showPagination: envLength > pageSize,
					}}
				/>
			)}
			{!!isSmScreen && (
				<Footer>
					<XMCSButton
						text={buttonText}
						onClick={() => setOpenCreateModal(true)}
						leftIcon={<Icon path={mdiPlus} />}
					/>
				</Footer>
			)}
			{openCreateModal && (
				<CreateEnvironment
					isOpen={openCreateModal}
					onClose={() => setOpenCreateModal(false)}
					isCreate={true}
					isCm={isCmEnv}
					environment={environments[0]}
				/>
			)}
		</Stack>
	);
};
